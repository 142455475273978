import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import './index.scss'
import {
  INavLinkGroup,
  INavStyles,
  IRenderFunction,
  IRenderGroupHeaderProps,
  Nav,
  Text
} from '@fluentui/react'
import { IRouteGroup, routes } from "./routing";
import { AvailableRoles } from "../../services/UseRoles";
import { fetchUserRoles, selectAll } from "../../store/actions/app/roles";
import { useDispatch, useSelector } from "react-redux";

const navStyles: Partial<INavStyles> = {
  root: {
    paddingTop: '20px',
    height: '100%',
    boxSizing: 'border-box',
    border: '1px solid #eee',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  group: {
    width: '100%',
    h3: {
      paddingLeft: '5px',
    },
  },
}

const onRenderGroupHeader: IRenderFunction<IRenderGroupHeaderProps> = props =>
  props ? (
    <Text variant="large" styles={{ root: { fontWeight: 'bold' } }} as="h3">
      {props.name}
    </Text>
  ) : null

const Navbar: React.FC = () => {
  const history = useHistory()
  const roles = useSelector(selectAll)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserRoles())
  }, []);
  
  const userHasAccess = (allowedRoles: (keyof typeof AvailableRoles)[]) => {
    return (
        allowedRoles?.some((allowedRole) =>
            roles?.some((userRole) => userRole.id === allowedRole)
        )
    )
  }

  const filteredRoutes = routes.filter(route => {
    return userHasAccess(route.allowedRoles)
  })
  
  const filteredLinks = (routeGroup: IRouteGroup) => {
    return routeGroup.links.filter(link => {
      return userHasAccess(link.allowedRoles)
    })
  }
  
  function onRenderLink(link: any, originalRender: any): any {
    return <div data-test={link.automationId}>{originalRender(link)}</div>;
  }

  return <Nav className="nav" styles={navStyles} data-test="data-navbar" onRenderGroupHeader={onRenderGroupHeader} onRenderLink={onRenderLink} 
              groups={filteredRoutes.map((route) => {
                if('links' in route){
                  return {
                    name: route.name,
                    links: filteredLinks(route).map((link) => {
                      return {
                        name: link.name,
                        key: link.key,
                        onClick: () => history.push(link.routePath),
                        automationId: link.automationId
                      }
                    })
                  } as INavLinkGroup
                } else {
                  return {} as INavLinkGroup
                }
              })} />
}

export default Navbar
