import { AllRoles, AvailableRoles } from "../../services/UseRoles";

export interface IRoute {
    name: string;
    key: string;
    routePath: string;
    automationId: string;
    allowedRoles: (keyof typeof AvailableRoles)[];
}

export interface IRouteGroup {
    name: string;
    links: IRoute[];
    allowedRoles: (keyof typeof AvailableRoles)[];
}

export const routes: (IRoute | IRouteGroup)[] = [
    {
        name: 'Monitoren',
        links: [
            {
                name: 'Dashboard',
                key: 'Dashboard',
                routePath: '/dashboard',
                automationId: 'nav-dashboard',
                allowedRoles: AllRoles
            },
            {
                name: 'Alle berichten',
                key: 'Berichten',
                routePath: '/berichten',
                automationId: 'nav-berichten',
                allowedRoles: ['Beheerder', 'DagelijksOnderhoud', 'Financieel']
            },
        ],
        allowedRoles: AllRoles
    },
    {
        name: 'Afhandelen',
        links: [
            {
                name: 'Niet bezorgde berichten',
                key: 'AfhandelenNietBezorgeBerichten',
                routePath: '/afhandelen/nietbezorgd',
                automationId: 'nav-afhandelen-nietbezorgd',
                allowedRoles: ['Beheerder','DagelijksOnderhoud', 'Financieel']
            },
        ],
        allowedRoles: ['Beheerder','DagelijksOnderhoud', 'Financieel']
    },
    {
        name: 'Beheren',
        links: [
            {
                name: 'Aannemers',
                key: 'Aannemers',
                routePath: '/aannemers',
                automationId: 'nav-aannemers',
                allowedRoles: ['Beheerder']
            },
            {
                name: 'Corporatie',
                key: 'corporatie',
                routePath: '/corporatie',
                automationId: 'nav-corporatie',
                allowedRoles: ['Beheerder']
            },
            {
                name: 'Business rules',
                key: 'BusinessRules',
                routePath: '/businessrules',
                automationId: 'nav-business_rules',
                allowedRoles: ['Beheerder']
            },
            {
                name: 'Mutatielog',
                key: 'mutatielog',
                routePath: '/mutatielog',
                automationId: 'nav-mutatielog',
                allowedRoles: ['Beheerder']
            },
        ],
        allowedRoles: ['Beheerder']
    },
]